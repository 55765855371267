<script lang="ts" setup>
const { t } = useT();
const { open } = useAppModals();
const { handleSupportButtonClick } = useSupportChat();

const { data: luckySpinData } = useGetLuckySpinData();
const { levelsRange, rankLeagueBlocked, type, rankLeagueEnabled, calculateLevel, handleUnlock } = useRankedSystem({
	open
});
const { refresh: refreshRankedWheelData } = useGetRankedWheelsData({ immediate: false });

const getBtnText = computed(() => {
	if (rankLeagueBlocked.value) {
		return t("Open Chat");
	}

	if (!rankLeagueEnabled.value) {
		return t("Activate");
	}

	if (luckySpinData.value?.wheelAvailable) {
		return t("Spin!");
	}

	return t("Play Now!");
});

const handleClickAction = () => {
	handleUnlock(() => {
		if (rankLeagueBlocked.value) {
			handleSupportButtonClick();
			return;
		}

		if (luckySpinData.value?.wheelAvailable) {
			open("LazyOModalWheelFortuneWheel");
			return;
		}

		open("LazyOModalVipClubGames");
	});
};

useEvent(["UPDATE_NUXT_DATA"], () => {
	refreshRankedWheelData();
});
</script>

<template>
	<div class="wrapper">
		<NuxtImg class="image" src="/nuxt-img/wheel/main-banner-wheel.png" alt="quest" width="240" height="240" />

		<div class="top">
			<div>
				<AText as="div" variant="tonk" :modifiers="['capitalize', 'bold']" class="text-tlalnepantla label">
					{{ type }}
				</AText>
				<NuxtLink to="/vip-club/">
					<AText as="div" variant="tlalpan" :modifiers="['underline', 'medium']" class="reward text-caracas">
						{{ t("FunzCity club rewards") }}
					</AText>
				</NuxtLink>
			</div>

			<AButton variant="primary" class="btn" size="lg" @click="handleClickAction">
				<AText variant="toledo" :modifiers="['semibold']" class="text-tlalnepantla">
					{{ getBtnText }}
				</AText>
			</AButton>
		</div>

		<AText v-if="rankLeagueBlocked" variant="topeka" class="text-cesena blocked" :modifiers="['bold']">
			{{ t("Vip club disabled. Please contact support") }}
		</AText>
		<div v-else class="bottom">
			<MVipClubLevels :active="calculateLevel" :levels="levelsRange" />
			<AText variant="topeka" :modifiers="['semibold']" class="text-сirebon description">
				{{ rankLeagueEnabled ? t("Spin the wheel every time you level up!") : t("Open level 1 & Spin the Wheel") }}
			</AText>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.wrapper {
	width: 552px;
	height: 228px;
	border-radius: 16px;
	background: var(--gosaba);
	display: flex;
	flex-direction: column;
	padding: gutter(3) gutter(2) gutter(7) gutter(25.5);
	position: relative;
	overflow: hidden;

	@include media-breakpoint-up(md) {
		&::before {
			content: "";
			background: linear-gradient(180deg, rgba(35, 25, 87, 0) 0%, #0f063d 100%);
			position: absolute;
			z-index: 1;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 50px;
		}
	}

	@include media-breakpoint-down(xl) {
		padding: gutter(3) gutter(2) gutter(7) gutter(19);
	}

	@include media-breakpoint-down(lg) {
		width: 100%;
	}

	@include media-breakpoint-down(md) {
		height: 158px;
		padding: gutter(2) gutter(2) gutter(2) gutter(13);
		overflow: hidden;
	}
}

.top {
	display: flex;
	flex-wrap: wrap;
	gap: gutter(1);
	justify-content: space-between;
}

.label {
	text-shadow: 0px 0px 6px var(--carapicuiba);
}

.reward:hover {
	cursor: pointer;
	text-decoration: none;
}

.blocked {
	margin-top: 30px;

	@include media-breakpoint-down(sm) {
		margin-top: gutter(1);
	}
}

.bottom {
	margin-top: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

.image {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	object-fit: cover;

	@include media-breakpoint-down(xl) {
		left: -40px;
	}

	@include media-breakpoint-down(md) {
		width: auto;
		left: -32px;
	}
}

.quest-link {
	margin: 0 auto;

	&:hover {
		cursor: pointer;
	}
}

.description {
	margin-top: gutter(1);
}

.btn {
	width: max-content;
	padding: gutter(1) gutter(3);
	min-width: 88px;

	@include media-breakpoint-down(md) {
		padding: gutter(1);
	}
}
</style>
